import Enum from './Enum.js';

const LocationSourceType = new Enum({
    0 : 'unknown',
    1 : 'GPS',
    2 : 'user provided',
    3 : 'google maps API',
    4 : 'system default',
});

export default LocationSourceType;