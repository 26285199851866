import Model from './Model.js';

import WorkOrderModel from './WorkOrderModel.js';
import ClientAccountModel from './ClientAccountModel.js';
import UserModel from './UserModel.js';
import ServiceRequestIssueModel from './ServiceRequestIssueModel.js';
import EquipmentModel from './EquipmentModel.js';
import ServiceLocationType from './ServiceLocationType.js';
import ShopLocationModel from './ShopLocationModel.js';
import ServiceLocationModel from './ServiceLocationModel.js';
import ServiceRequestStatusType from './ServiceRequestStatusType.js';
import InvoiceModel from './InvoiceModel.js';

class ServiceRequestModel extends Model {
    constructor(values, config = {}){
        super(config);

        this.prop('id', Number, true);
        this.prop('workOrders', [WorkOrderModel], false);
        this.prop('clientAccount', ClientAccountModel, true);
        this.prop('requestor', UserModel, true);
        this.prop('equipmentIssues', [ServiceRequestIssueModel], false);
        this.prop('equipment', [EquipmentModel], false);
        this.prop('serviceLocationType', ServiceLocationType, false);
        this.prop('shopLocation', ShopLocationModel, true);
        this.prop('serviceLocation', ServiceLocationModel, true);
        this.prop('urgentRequest', Boolean, false);
        this.prop('availableImmediately', Boolean, true);
        this.prop('startTime', Date, true);
        this.prop('endTime', Date, true);
        this.prop('status', ServiceRequestStatusType, false);
        this.prop('invoice', InvoiceModel, true);
        this.prop('createdDate', Date, false);

        this.populate(values, config);

    }
    static name = 'ServiceRequestModel';
    static source = 'ServiceRequest';
}
window[Symbol.for(ServiceRequestModel.name)] = ServiceRequestModel;

export default ServiceRequestModel;