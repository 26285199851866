import Enum from './Enum.js';

const WallType = new Enum({
    0 : 'unknown',
    1 : 'Bulletex',
    2 : 'Kemlite',
    3 : 'plastic',
    4 : 'plywood',
    5 : 'sheet',
});

export default WallType;