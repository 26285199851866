import Model from './Model.js';

import InvoiceModel from './InvoiceModel.js';
import PaymentModel from './PaymentModel.js';

class PaymentAllocationModel extends Model {
    constructor(values, config = {}){
        super(config);

        this.prop('id', Number, true);
        this.prop('created', Date, false);
        this.prop('invoice', InvoiceModel, false);
        this.prop('payment', PaymentModel, false);
        this.prop('amount', Number, false);

        this.populate(values, config);

    }
    static name = 'PaymentAllocationModel';
    static source = 'PaymentAllocation';
}
window[Symbol.for(PaymentAllocationModel.name)] = PaymentAllocationModel;

export default PaymentAllocationModel;