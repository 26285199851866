import Model from './Model.js';

import ServiceRequestIssueModel from './ServiceRequestIssueModel.js';
import RejectionReasonType from './RejectionReasonType.js';
import UserModel from './UserModel.js';

class RejectedServiceRequestIssueModel extends Model {
    constructor(values, config = {}){
        super(config);

        this.prop('id', Number, true);
        this.prop('issue', ServiceRequestIssueModel, false);
        this.prop('rejectionReason', RejectionReasonType, false);
        this.prop('description', String, true);
        this.prop('rejectedBy', UserModel, false);

        this.populate(values, config);

    }
    static name = 'RejectedServiceRequestIssueModel';
    static source = 'RejectedServiceRequestIssue';
}
window[Symbol.for(RejectedServiceRequestIssueModel.name)] = RejectedServiceRequestIssueModel;

export default RejectedServiceRequestIssueModel;