import Model from './Model.js';

import ServiceRequestIssueModel from './ServiceRequestIssueModel.js';
import UserModel from './UserModel.js';

class AcceptedServiceRequestIssueModel extends Model {
    constructor(values, config = {}){
        super(config);

        this.prop('id', Number, true);
        this.prop('issue', ServiceRequestIssueModel, false);
        this.prop('partsEstimate', Number, false);
        this.prop('laborEstimate', Number, false);
        this.prop('taxesEstimate', Number, false);
        this.prop('description', String, true);
        this.prop('createdBy', UserModel, false);

        this.populate(values, config);

    }
    static name = 'AcceptedServiceRequestIssueModel';
    static source = 'AcceptedServiceRequestIssue';
}
window[Symbol.for(AcceptedServiceRequestIssueModel.name)] = AcceptedServiceRequestIssueModel;

export default AcceptedServiceRequestIssueModel;