import Enum from './Enum.js';

const ServiceIssueType = new Enum({
    0 : 'inspection (PM/DOT)',
    1 : 'brakes',
    2 : 'suspension',
    3 : 'tires',
    4 : 'wheel-end',
    5 : 'electrical & lighting',
    6 : 'GPS / Telematics',
    7 : 'cargo handling',
    8 : 'coupling system',
    9 : 'reefer component',
    10 : 'door',
    11 : 'body component',
    12 : 'frame & landing gear',
    13 : 'other',
});

export default ServiceIssueType;