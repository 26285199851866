import Model from './Model.js';

import AcceptedServiceRequestIssueModel from './AcceptedServiceRequestIssueModel.js';
import ImageModel from './ImageModel.js';
import UserModel from './UserModel.js';
import TaskStatusType from './TaskStatusType.js';

class WorkOrderTaskModel extends Model {
    constructor(values, config = {}){
        super(config);

        this.prop('id', Number, true);
        this.prop('issue', AcceptedServiceRequestIssueModel, false);
        this.prop('notes', String, true);
        this.prop('images', [ImageModel], false);
        this.prop('workPerformedBy', UserModel, true);
        this.prop('status', TaskStatusType, false);

        this.populate(values, config);

    }
    static name = 'WorkOrderTaskModel';
    static source = 'WorkOrderTask';
}
window[Symbol.for(WorkOrderTaskModel.name)] = WorkOrderTaskModel;

export default WorkOrderTaskModel;