import Enum from './Enum.js';

const InvoiceStatusType = new Enum({
    0 : 'pending',
    1 : 'unpaid',
    2 : 'paid',
    3 : 'disputed',
    4 : 'closed',
});

export default InvoiceStatusType;