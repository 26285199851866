import Enum from './Enum.js';

const ServiceRequestStatusType = new Enum({
    0 : 'requested',
    1 : 'accepted',
    2 : 'scheduled',
    3 : 'in progress',
    4 : 'completed',
    5 : 'failed',
});

export default ServiceRequestStatusType;