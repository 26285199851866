import Model from './Model.js';

import ServiceRequestModel from './ServiceRequestModel.js';
import ClientAccountModel from './ClientAccountModel.js';
import EquipmentModel from './EquipmentModel.js';
import PaymentModel from './PaymentModel.js';
import PaymentAllocationModel from './PaymentAllocationModel.js';
import InvoiceStatusType from './InvoiceStatusType.js';

class InvoiceModel extends Model {
    constructor(values, config = {}){
        super(config);

        this.prop('id', Number, true);
        this.prop('invoiceNumber', String, true);
        this.prop('invoiceDate', Date, true);
        this.prop('dueDate', Date, true);
        this.prop('purchaseOrderNumber', String, true);
        this.prop('serviceRequest', ServiceRequestModel, false);
        this.prop('clientAccount', ClientAccountModel, false);
        this.prop('total', Number, true);
        this.prop('balanceDue', Number, true);
        this.prop('equipment', [EquipmentModel], false);
        this.prop('payments', [PaymentModel], false);
        this.prop('paymentAllocations', [PaymentAllocationModel], false);
        this.prop('status', InvoiceStatusType, false);

        this.populate(values, config);

    }
    static name = 'InvoiceModel';
    static source = 'Invoice';
}
window[Symbol.for(InvoiceModel.name)] = InvoiceModel;

export default InvoiceModel;