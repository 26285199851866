import Model from './Model.js';

import WorkOrderTaskModel from './WorkOrderTaskModel.js';
import AcceptedServiceRequestIssueModel from './AcceptedServiceRequestIssueModel.js';
import RejectedServiceRequestIssueModel from './RejectedServiceRequestIssueModel.js';
import ServiceRequestModel from './ServiceRequestModel.js';
import EquipmentModel from './EquipmentModel.js';
import UserModel from './UserModel.js';
import WorkOrderStatusType from './WorkOrderStatusType.js';
import ShopLocationModel from './ShopLocationModel.js';
import ServiceLocationModel from './ServiceLocationModel.js';
import ServiceLocationType from './ServiceLocationType.js';

class WorkOrderModel extends Model {
    constructor(values, config = {}){
        super(config);

        this.prop('id', Number, true);
        this.prop('workOrderNumber', String, false);
        this.prop('tasks', [WorkOrderTaskModel], false);
        this.prop('acceptedIssues', [AcceptedServiceRequestIssueModel], false);
        this.prop('rejectedIssues', [RejectedServiceRequestIssueModel], false);
        this.prop('serviceRequest', ServiceRequestModel, true);
        this.prop('equipment', [EquipmentModel], false);
        this.prop('createdBy', UserModel, true);
        this.prop('createdDate', Date, false);
        this.prop('status', WorkOrderStatusType, false);
        this.prop('scheduledStartTime', Date, true);
        this.prop('scheduledEndTime', Date, true);
        this.prop('completedDate', Date, true);
        this.prop('laborEstimateTotal', Number, true);
        this.prop('partsEstimateTotal', Number, true);
        this.prop('taxesEstimateTotal', Number, true);
        this.prop('estimateTotal', Number, true);
        this.prop('shopLocation', ShopLocationModel, true);
        this.prop('serviceLocation', ServiceLocationModel, true);
        this.prop('serviceLocationType', ServiceLocationType, false);

        this.populate(values, config);

    }
    static name = 'WorkOrderModel';
    static source = 'WorkOrder';
}
window[Symbol.for(WorkOrderModel.name)] = WorkOrderModel;

export default WorkOrderModel;