import Model from './Model.js';

import PaymentType from './PaymentType.js';
import PaymentAllocationModel from './PaymentAllocationModel.js';

class PaymentModel extends Model {
    constructor(values, config = {}){
        super(config);

        this.prop('id', Number, true);
        this.prop('created', Date, false);
        this.prop('paymentType', PaymentType, false);
        this.prop('paymentAllocations', [PaymentAllocationModel], false);
        this.prop('amount', Number, false);

        this.populate(values, config);

    }
    static name = 'PaymentModel';
    static source = 'Payment';
}
window[Symbol.for(PaymentModel.name)] = PaymentModel;

export default PaymentModel;