import Model from './Model.js';

import MakeModel from './MakeModel.js';
import ClientAccountModel from './ClientAccountModel.js';
import ServiceRequestModel from './ServiceRequestModel.js';
import ServiceRequestIssueModel from './ServiceRequestIssueModel.js';
import DoorType from './DoorType.js';
import SuspensionType from './SuspensionType.js';
import WallType from './WallType.js';

class EquipmentModel extends Model {
    constructor(values, config = {}){
        super(config);

        this.prop('id', Number, true);
        this.prop('make', MakeModel, false);
        this.prop('clientAccount', ClientAccountModel, true);
        this.prop('serviceRequests', [ServiceRequestModel], false);
        this.prop('activeServiceRequests', [ServiceRequestModel], false);
        this.prop('serviceRequestIssues', [ServiceRequestIssueModel], false);
        this.prop('trailerNumber', String, true);
        this.prop('year', Number, true);
        this.prop('nominalLength', Number, true);
        this.prop('description', String, true);
        this.prop('contractActive', Boolean, false);
        this.prop('vin', String, false, {"length":17});
        this.prop('doorType', DoorType, false);
        this.prop('suspensionType', SuspensionType, false);
        this.prop('wallType', WallType, false);
        this.prop('gpsEquipped', Boolean, false);
        this.prop('registrationState', String, true);
        this.prop('plateNumber', String, true);
        this.prop('owner', Number, false);
        this.prop('nextPMDate', Date, true);

        this.populate(values, config);

    }
    static name = 'EquipmentModel';
    static source = 'Equipment';
}
window[Symbol.for(EquipmentModel.name)] = EquipmentModel;

export default EquipmentModel;