import Model from './Model.js';

import ServiceRequestModel from './ServiceRequestModel.js';
import EquipmentModel from './EquipmentModel.js';
import ImageModel from './ImageModel.js';
import ServiceIssueType from './ServiceIssueType.js';

class ServiceRequestIssueModel extends Model {
    constructor(values, config = {}){
        super(config);

        this.prop('id', Number, true);
        this.prop('serviceRequest', ServiceRequestModel, false);
        this.prop('equipment', EquipmentModel, false);
        this.prop('images', [ImageModel], false);
        this.prop('serviceIssueType', ServiceIssueType, false);
        this.prop('description', String, true);

        this.populate(values, config);

    }
    static name = 'ServiceRequestIssueModel';
    static source = 'ServiceRequestIssue';
}
window[Symbol.for(ServiceRequestIssueModel.name)] = ServiceRequestIssueModel;

export default ServiceRequestIssueModel;